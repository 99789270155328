@font-face {
  font-family: "Hellix-bold";
  src: url("./fonts/Hellix-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

#prompt-restart-cont {
  position: relative;
}

#prompt-string p {
  font-family: Hellix-bold;
  font-weight: 700;
  letter-spacing: 0em;
  color: #731fb5;
}

.loading-prompt{
  display: flex;
  justify-content: center;
}

.loading-prompt p{
  width: 80%;
}

.restartbtn {
  border: 2px solid black;
  position: absolute;
  right: 0;
  top: 20%;
  padding: 5px 20px;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restartbtn:hover {
  text-decoration: none;
}

.restartbtn > div {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  color: #731fb5;
}

.restartbtn > img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.select-one > p {
  font-family: Hellix;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.image-container > div {
  aspect-ratio: 4 / 7;
  padding: 0.6rem;
  cursor: pointer;
}

.selectedImage img {
  background-color: rgb(209 213 219);
  border: 2px solid #731fb5;
  /* box-shadow: 5px 5px 5px #731fb5; */
  transition: .3s ease;
  padding: 5px;
}
.selectText {
  font-family: Hellix;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.nextbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nextbtn div {
  font-family: Hellix;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  letter-spacing: 0em;
}
.nextbtn img {
  margin-left: 10px;
  width: 25px;
  height: 25px;
}

.nextbtn img:hover {
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 5px 2px 2px grey;
  transition: 0.5s;
}

.nextbtn img:active {
  box-shadow: none;
}
#preloader {
  position: fixed !important;
  inset: 0;
  background-color: #fff;
  z-index: 9999;
}

.squareloaders {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.squareloaders > div {
  position: absolute;
  animation: loader 1s ease-in-out forwards infinite alternate;
}
#one {
  right: 3%;
  top: 10%;
  height: 128px;
  width: 90px;
}

#two {
  left: 3%;
  top: 2%;
  height: 210px;
  width: 150px;
}

#three {
  right: 8%;
  bottom: 0;
  width: 260.2px;
  height: 352px;
}

#four {
  left: 6%;
  top: 45%;
  width: 50px;
  height: 78px;
}

#five {
  left: 25%;
  top: 63%;
  width: 58px;
  height: 83px;
}

@keyframes loader {
  0% {
    background-color: inherit;
  }

  50% {
    background-color: #b98fd9;
  }

  100% {
    /* background-color: rgba(117, 31, 183, 0.5); */
    background-color: #731fb5;
  }
}
