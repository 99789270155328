.logo {
  color: #212529;
  text-decoration: none;
}
.logo:hover {
  color: #212529;
  text-decoration: none;
}
.restartbtn-div {
  justify-content: end;
}
@media screen and (max-width: 580px) {
  form.query-form > div {
    width: 100% !important;
  }
  .landingtext {
    font-size: 1.2rem !important;
    line-height: unset !important;
  }
  .landingphrase {
    font-size: 0.9rem !important;
  }
  .inputsection p,
  #prompEg p {
    font-size: 0.7rem !important;
  }
  .logo {
    font-size: 2rem !important;
  }
  #prompt-string p {
    font-size: 1.2rem !important;
    line-height: normal !important;
  }
  /* .image-container > div {
  } */

  .restartbtn-div {
    justify-content: center;
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.checkout-right {
  background-color: #f5f5f5;
}
#checkout .breadcrumb {
  background-color: unset !important;
}
#checkout .breadcrumb-item + .breadcrumb-item::before {
  content: url(./images/chevron-right.svg) !important;
}
#checkout .postal_code {
  padding-top: 0.61rem !important;
  padding-bottom: 0.61rem !important;
}
.select-input-label {
  position: absolute;
  z-index: 2;
  font-size: 10px;
  color: #969696;
  padding-left: 0.94rem;
}
.text-muted {
  font-size: 12px;
  color: #969696;
}
.text-mute {
  color: #969696;
}
.sub-total,
.text-sm {
  font-size: 14px;
}
.text-xs {
  font-size: 12px;
}
.text-md {
  font-size: 16px;
}

.total,
.text-lg {
  font-size: 18px;
}
.font-weight-bold {
  font-weight: bold !important;
}
.clickable {
  cursor: pointer;
}
html,
body,
#root,
#checkout {
  height: 100%;
}
#checkout {
  overflow-y: scroll !important;
}
.StripeElement input {
  border: 1px solid #212529;
}
.payment-card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.modal-open {
  overflow: hidden;
  padding-right: 8px;
}
