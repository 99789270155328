@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Medium.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SFPrRounded";
  src: url("./fonts/sf-pro-rounded.ttf");
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: Hellix;
}
.landing-page {
  background-size: cover;
  background-position: center;
  min-width: 10%;
  min-height: 100%;
  background-repeat: no-repeat;
  border: 1px solid red;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* animation: fadeInOut 1s ease-in-out infinite alternate; */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  font-family: SFPrRounded;
  font-size: 48px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0em;
}

.landingtext {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
}

.landingphrase {
  font-size: 17.5px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}
.inputsection {
  padding-top: 50px;
}
.inputsection p,
#prompEg p {
  font-size: 13.5px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

form.query-form {
  display: flex;
  justify-content: center;
}
form.query-form > div {
  width: 38%;
  position: relative;
  border: 1px solid black;
  border-radius: 20px;
}
input {
  width: 85%;
  padding: 15px 20px;
  border-radius: 20px;
  outline: none;
  border: none;
}

form.query-form input::placeholder {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}

#searchbtn {
  position: absolute;
  right: 5%;
  top: 17%;
  background-color: #731fb5;
  color: #fff;
}

#searchbtn:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
}
#email {
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.socials {
  display: flex;
  justify-content: space-around;
}

.socials img {
  margin: 0 10px;
}

.changeimage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.changeimage img {
  position: absolute;
  top: -23%;
  width: 85%;
  z-index: -1;
}

.shimmer {
  line-height: inherit;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  margin-bottom: 1rem;
  height: 1rem;
  width: 24rem;
  border-radius: 9999px;
  background-color: rgb(229 231 235 / 1);
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.w-10 {
  width: 2.5rem;
}
.h-10 {
  height: 2.5rem;
}
