#prompt-restart-cont {
  display: flex;
  align-items: center;
}

#prompt-string {
  /* padding-left: 5%; */
  width: 63%;
  text-align: center;
}

.prompt-back {
  width: 20%;
}

.restartbtn {
  position: static;
  display: inline-flex;
  width: 7rem;
}

.price {
  font-family: Hellix;
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: right;
}

@media (max-width: 991px) {
  #prompt-string {
    width: 100%;
  }
}